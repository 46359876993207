.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  
}

.hero__title {
  position: absolute;
  bottom: 15%;
  right: 10%;
  color: rgb(231, 228, 228);
  font-size: 4rem ;
  padding: 15px;
}
